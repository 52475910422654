export const LOGIN = 'LOGIN';
export const LOGIN_CLIENT = 'LOGIN_CLIENT';
export const LOGIN_HOME_VALUATION = 'LOGIN_HOME_VALUATION';
export const ACTIVATE = 'ACTIVATE';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const REDUCE_SECURITY_ELEMENT = 'REDUCE_SECURITY_ELEMENT';
export const REDUCE_SECURITY = 'REDUCE_SECURITY';
export const ENABLE_MFA = 'ENABLE_MFA';
export const DISABLE_MFA = 'DISABLE_MFA';
export const RESEND_CODE = 'RESEND_CODE';
export const RESEND_CLIENT_CODE = 'RESEND_CLIENT_CODE';
export const REDUCE_PARAMETER = 'REDUCE_PARAMETER';
export const REDUCE_PARAMETERS = 'REDUCE_PARAMETERS';
