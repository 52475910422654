import React from 'react';
import {
    BrowserRouter,
    Route,
    Switch,
} from 'react-router-dom';

import { Container } from 'metrix-common';

// -- Dynamic Imports Starts --

// -- Dynamic Imports Ends --

const BrokerCompanySignUp = React.lazy(() => import('../../containers/BrokerCompanySignup'));
const Home = React.lazy(() => import('../../containers/Home'));
const Workflow = React.lazy(() => import('../../components/Workflow'));
const Quote = React.lazy(() => import('../../containers/Quote'));
const FourOhFour = React.lazy(() => import('../../containers/FourOhFour'));
const Redirect = React.lazy(() => import('../../containers/Redirect'));
const PrivacyPolicy = React.lazy(() => import('../../containers/PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('../../containers/TermsOfService'));
const Login = React.lazy(() => import('../../containers/Login'));
const LoginClient = React.lazy(() => import('../../containers/LoginClient'));
const Dashboard = React.lazy(() => import('../../containers/Dashboard'));
const Product = React.lazy(() => import('../../containers/Product'));
const Embedded = React.lazy(() => import('../../containers/Embedded'));
const Summary = React.lazy(() => import('../../containers/Summary'));
const HomeValuation = React.lazy(() => import('../../containers/HomeValuation'));
const HomeValuation2 = React.lazy(() => import('../../containers/HomeValuation2'));
const HomeValuationLogin = React.lazy(() => import('../../containers/HomeValuationLogin'));
const HomeValuationError = React.lazy(() => import('../../containers/HomeValuationError'));
const Payment = React.lazy(() => import('../../containers/Payment'));
const DebitSetup = React.lazy(() => import('../../containers/DebitSetup'));
const CreditSetup = React.lazy(() => import('../../containers/CreditSetup'));
const PaymentError = React.lazy(() => import('../../containers/PaymentError'));
const Error = React.lazy(() => import('../../containers/Error'));
const PaymentNotRequired = React.lazy(() => import('../../containers/PaymentNotRequired'));
const ClientPrivacy = React.lazy(() => import('../../containers/ClientPrivacy'));
const ClientTerms = React.lazy(() => import('../../containers/ClientTerms'));
const ThankYou = React.lazy(() => import('../../containers/ThankYou'));
const FinancingPrivacyPolicy = React.lazy(() => import('../../containers/FinancingPrivacyPolicy'));

const Routes = (props) => {
    return (
        <BrowserRouter basename={process.env.REACT_APP_SUBFOLDER_NAME ? `/${process.env.REACT_APP_SUBFOLDER_NAME}` : '/'}>
            <Container {...props}>
                <React.Suspense fallback={<div />}>
                    <Switch>
                        <Route exact path='/' render={(routeProps) => <Home {...routeProps} {...props} />} />
                        <Route exact path='/login' render={(routeProps) => <Login {...routeProps} {...props} />} />
                        <Route exact path='/client/login' render={(routeProps) => <LoginClient {...routeProps} {...props} />} />
                        <Route exact path='/broker-company-signup' render={(routeProps) => <BrokerCompanySignUp {...routeProps} {...props} />} />
                        <Route exact path='/client-privacy' render={(routeProps) => <ClientPrivacy {...routeProps} {...props} />} />
                        <Route exact path='/client-terms' render={(routeProps) => <ClientTerms {...routeProps} {...props} />} />

                        <Route path='/dashboard' render={(routeProps) => <Dashboard {...routeProps} {...props} />} />
                        <Route exact path='/profile' render={(routeProps) => <Summary {...routeProps} {...props} />} />
                        <Route exact path='/workflow' render={(routeProps) => <Workflow {...routeProps} {...props} />} />
                        <Route exact path='/quote' render={(routeProps) => <Quote {...routeProps} {...props} />} />
                        <Route exact path='/homevaluation2' render={(routeProps) => <HomeValuation2 {...routeProps} {...props} />} />
                        <Route exact path='/homevaluation/client' render={(routeProps) => <HomeValuation {...routeProps} {...props} />} />
                        <Route exact path='/homevaluation' render={(routeProps) => <HomeValuation {...routeProps} {...props} />} />
                        <Route exact path='/homevaluation/login' render={(routeProps) => <HomeValuationLogin {...routeProps} {...props} />} />
                        <Route exact path='/homevaluation/error' render={(routeProps) => <HomeValuationError {...routeProps} {...props} />} />

                        {/* -- Dynamic Routes Start -- */}

                        {/* -- Dynamic Routes End -- */}

                        <Route exact path='/send-quote' render={() => 'send-quote page'} />
                        <Route exact path='/my-quotes' render={() => 'my-quotes page'} />
                        <Route exact path='/my-info' render={() => 'my-info page'} />
                        <Route exact path='/my-claims' render={() => 'my-claims page'} />
                        <Route exact path='/contact-my-broker' render={() => 'contact-my-broker page'} />
                        <Route exact path='/thankyou' render={(routeProps) => <ThankYou {...routeProps} {...props} />} />

                        <Route path='/payment' render={(routeProps) => <Payment {...routeProps} {...props} />} />
                        <Route path='/credit-setup' render={(routeProps) => <CreditSetup {...routeProps} {...props} />} />
                        <Route path='/debit-setup' render={(routeProps) => <DebitSetup {...routeProps} {...props} />} />
                        <Route path='/payment-not-required' render={(routeProps) => <PaymentNotRequired {...routeProps} {...props} />} />
                        <Route path='/payment-error' render={(routeProps) => <PaymentError {...routeProps} {...props} />} />
                        <Route path='/financing-privacy-policy' render={(routeProps) => <FinancingPrivacyPolicy {...routeProps} {...props} />} />

                        <Route exact path='/privacy' render={(routeProps) => <PrivacyPolicy {...routeProps} {...props} />} />
                        <Route exact path='/terms' render={(routeProps) => <TermsOfService {...routeProps} {...props} />} />
                        <Route exact path='/redirect' render={(routeProps) => <Redirect {...routeProps} {...props} />} />
                        <Route exact path='/404' render={(routeProps) => <FourOhFour type={'api_fail'} {...routeProps} {...props} />} />
                        <Route exact path='/error' render={(routeProps) => <Error {...routeProps} {...props} />} />

                        <Route path={'/@:agentName'} render={(routeProps) => <Home {...routeProps} {...props} />} />
                        <Route path={'/product/:productName/@:agentName'} render={(routeProps) => <Product {...routeProps} {...props} />} />
                        <Route path={'/product/:productName'} render={(routeProps) => <Product {...routeProps} {...props} />} />
                        <Route path='/embedded' render={(routeProps) => <Embedded {...routeProps} {...props} />} />
                        <Route render={routeProps => <FourOhFour {...routeProps} {...props} />} />
                    </Switch>
                </React.Suspense>
            </Container>
        </BrowserRouter>
    );
}

export default Routes;
