import dotProp from 'dot-prop-immutable';

import {
    REDUCE_CLIENT_DASHBOARD,
    REDUCE_CLIENT_AGENT,
    REDUCE_CLIENT_AGENT_ELEMENTS,
    REDUCE_CLIENT_ENTITY,
    UPDATE_MORTGAGE_INFO
} from '../../constants/actions/client';

const initialState = {
    entity: null,
    agent: null,
    dashboard: null,
};

function clientReducer(state = initialState, action) {
    switch (action.type) {
        case REDUCE_CLIENT_DASHBOARD:
            return dotProp.set(state, 'dashboard', action.payload);
        case REDUCE_CLIENT_AGENT:
            return dotProp.set(state, 'agent', action.payload);
        case REDUCE_CLIENT_AGENT_ELEMENTS:
            return dotProp.set(state, 'agent', { ...state.agent, [action.payload.code]: action.payload.value });
        case REDUCE_CLIENT_ENTITY:
            return dotProp.set(state, 'entity', action.payload);
        case UPDATE_MORTGAGE_INFO:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    sections: state.dashboard.sections.map(section => {
                        if (section.type === 'mortgageInfo') {
                            return {
                                ...section,
                                content: {
                                    ...section.content,
                                    mortgageBalance: action.payload.mortgageBalance,
                                    purchasePrice: action.payload.purchasePrice,
                                    interestRate: action.payload.interestRate,
                                    amortizationTerm: action.payload.amortizationTerm,
                                    formattedInterestRate: action.payload.formattedInterestRate,
                                    formattedMortgageBalance: action.payload.formattedMortgageBalance,
                                    formattedPurchasePrice: action.payload.formattedPurchasePrice
                                }
                            };
                        }
                        if (section.type === 'valueDisplay') {
                            return {
                                ...section,
                                content: {
                                    ...section.content,
                                    formattedMortgageBalance: action.payload.formattedMortgageBalance,
                                    mortgageBalance: action.payload.mortgageBalance,
                                    equityAvailable: action.payload.equityAvailable
                                }
                            };
                        }
                        return section;
                    })
                }
            };
        default:
            return state;
    }
}

export default clientReducer;